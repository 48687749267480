import { useContext } from "react";

import { TimesheetContext } from "./TimesheetContext";

export function useTimesheetContext() {
  const context = useContext(TimesheetContext);
  if (context === undefined) {
    throw new Error("useTimesheetContext must be within a Timesheet provider");
  }

  return context;
}