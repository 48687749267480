import { useQuery } from "react-query";

import { useApi, useAuth } from "hooks";
import dayjs from "dayjs";
import { TimeOff } from "../models";

export const useGetTimeOffRequestsQueryKeys = {
  getTimeOff: "get-time-off-requests"
};

export type UseGetTimeOffRequestsKeyT = keyof typeof useGetTimeOffRequestsQueryKeys;

export interface UseGetTimeOffRequestsParamsT {
  isAutoRefetching?: boolean;
  onError?: (error) => void;
  onSuccess?: (data) => void;
  queryKey?: UseGetTimeOffRequestsKeyT;
  select?;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export interface UseGetTimeOffRequestsReturnT {
  data: TimeOff[] | undefined;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export function useGetTimeOffRequestsQuery(
  params: UseGetTimeOffRequestsParamsT
): UseGetTimeOffRequestsReturnT {
  const { onError, onSuccess, isAutoRefetching = true, startDate, endDate } = params;
  const { user } = useAuth();
  const api = useApi();

  const fetchTimeOff = async ({ queryKey }) => {
    const [, employeeId, start, end] = queryKey;
    try {
      const response = await api.get(
        `time_off/requests?start=${start}&end=${end}&employeeId=${employeeId}&status=approved,requested`
      );
      return response.data as TimeOff[];
    } catch (error) {
      throw new Error("Unable to fetch timesheet entries");
    }
  };
  
  const startKey = startDate.format("YYYY-MM-DD");
  const endKey = endDate.format("YYYY-MM-DD");

  const queryInfo = useQuery({
    queryKey: [useGetTimeOffRequestsQueryKeys.getTimeOff, user?.employeeId, startKey, endKey],
    queryFn: fetchTimeOff,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching !== undefined ? isAutoRefetching : true
  });

  return {
    data: queryInfo.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
