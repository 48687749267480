import React, { ReactNode, createContext, useReducer } from "react";

import produce from "immer";

import { TimesheetContextState, TimesheetUpdates } from "./TimesheetContextState";
import { getNextSunday, getPreviousMonday } from "../utils";
import dayjs from "dayjs";

export const initialTimesheetContextState: TimesheetContextState = {
  activeTimesheet: { projects: [] },
  pendingChanges: [],
  projects: [],
  startDate: getPreviousMonday(dayjs()),
  endDate: getNextSunday(dayjs()),
  autoSync: false,
  isLoading: false,
  isSyncing: false,
  error: ""
};
export const TimesheetContext = createContext(initialTimesheetContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const TimesheetDispatchContext =
  createContext<React.Dispatch<Action<TimesheetUpdates, "update">>>(undefined);

function TimesheetReducer(
  state: TimesheetContextState,
  action: Action<TimesheetUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface TimesheetProviderModel {
  children: ReactNode;
  state?: TimesheetContextState;
}

export function TimesheetProvider({
  children,
  state: overrideState
}: TimesheetProviderModel) {
  const [state, dispatch] = useReducer(
    TimesheetReducer,
    overrideState ?? initialTimesheetContextState
  );

  return (
    <TimesheetContext.Provider value={state}>
      <TimesheetDispatchContext.Provider value={dispatch}>
        {children}
      </TimesheetDispatchContext.Provider>
    </TimesheetContext.Provider>
  );
}
