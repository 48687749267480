import { useCallback } from "react";
import { useTimesheetContext } from "../contexts";
import { useTimesheetEntriesMutation } from "../mutations";
import useProjectAdder from "./useProjectAdder";

const useCommonProjects = () => {
  const { activeTimesheet, projects, startDate, endDate } = useTimesheetContext();
  const { addProjectToTimesheet } = useProjectAdder();

  const existingProjectIds = activeTimesheet.projects.map((project) => project.id);

  const handleTimesheetLoaded = useCallback((data) => {
    
    const newProjects = data?.reduce((acc, curr) => {
      if (!curr.projectInfo?.project) return acc;

      if (!acc.includes(curr.projectInfo.project.id)) {
        acc.push(curr.projectInfo.project.id);
      }

      return acc;
    }
    , []);


    const toAdd = []
    newProjects?.forEach((id) => {
      if (existingProjectIds.includes(id)) return;
      
      const match = projects.find((p) => p.id === id);
      if (match) {
        toAdd.push(match)
      }
    });

    if (toAdd.length > 0) {
      addProjectToTimesheet(toAdd);
    }

  }, [addProjectToTimesheet, existingProjectIds, projects]);

  
  const mutation = useTimesheetEntriesMutation({ onSuccess: handleTimesheetLoaded });

  const getProjects = useCallback(() => {
    const previousStartDate = startDate.subtract(7, "days");
    const previousEndDate = endDate.subtract(7, "days");
  
    mutation.mutate({ startDate: previousStartDate, endDate: previousEndDate });
  }, [mutation, startDate, endDate]);

  return {
    isLoading: mutation.isLoading,
    addCommonProjects: getProjects,
  };
};

export default useCommonProjects;