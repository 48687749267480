import { Select, DatePicker, Button, Modal } from "antd";
import { useTimesheetContext, useTimesheetDispatch } from "../contexts";
import styled from "styled-components";
import dayjs from "dayjs";
import { getNextSunday, getPreviousMonday } from "../utils";
import { useCallback } from "react";

const { RangePicker } = DatePicker;

const thisMonday = getPreviousMonday(dayjs());
const thisSunday = getNextSunday(dayjs());
const lastMonday = getPreviousMonday(dayjs().add(-1, "week"));
const lastSunday = getNextSunday(dayjs().add(-1, "week"));

const DateSelector: React.FC = () => {
  const { startDate, endDate, pendingChanges } = useTimesheetContext();
  const dispatch = useTimesheetDispatch();

  const hasPendingChanges = pendingChanges?.length > 0;

  const showConfirm = useCallback((onConfirm: () => void) => {
    Modal.confirm({
      title: "You have unsaved changes",
      content: "Do you want to proceed without saving your changes?",
      onOk: onConfirm,
    });
  }, []);

  const handlePickerChange = useCallback((value: string) => {
    const changeDates = () => {
      if (value === "previous") {
        dispatch({
          payload: {
            startDate: lastMonday,
            endDate: lastSunday
          }
        });
      } else if (value === "current") {
        dispatch({
          payload: {
            startDate: thisMonday,
            endDate: thisSunday
          }
        });
      }
    };

    if (hasPendingChanges) {
      showConfirm(changeDates);
    } else {
      changeDates();
    }
  }, [dispatch, hasPendingChanges, showConfirm]);

  const handleLastWeek = useCallback(() => {
    const changeDates = () => {
      dispatch({
        payload: {
          startDate: startDate.subtract(1, "week"),
          endDate: endDate.subtract(1, "week")
        }
      });
    };

    if (hasPendingChanges) {
      showConfirm(changeDates);
    } else {
      changeDates();
    }
  }, [dispatch, startDate, endDate, hasPendingChanges, showConfirm]);

  const handleNextWeek = useCallback(() => {
    const changeDates = () => {
      dispatch({
        payload: {
          startDate: startDate.add(1, "week"),
          endDate: endDate.add(1, "week")
        }
      });
    };

    if (hasPendingChanges) {
      showConfirm(changeDates);
    } else {
      changeDates();
    }
  }, [dispatch, startDate, endDate, hasPendingChanges, showConfirm]);

  const handleDateRangeChange = useCallback((date: dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs]) => {
    const changeDates = () => {
      if (Array.isArray(date)) {
        dispatch({
          payload: {
            startDate: date[0],
            endDate: date[1]
          }
        });
      } else {
        dispatch({
          payload: {
            startDate: date,
            endDate: date.add(6, "day")
          }
        });
      }
    };

    if (hasPendingChanges) {
      showConfirm(changeDates);
    } else {
      changeDates();
    }
  }, [dispatch, hasPendingChanges, showConfirm]);

  const isCustom = startDate.day() !== 1 || endDate.day() !== 0;

  const options = [
    { value: "previous", label: "Last Week" },
    { value: "current", label: "Current Week" },
    { value: "custom", label: "Custom" }
  ];

  const picker =
    startDate.isSame(thisMonday, "date") && endDate.isSame(thisSunday, "date")
      ? "current"
      : startDate.isSame(lastMonday, "date") && endDate.isSame(lastSunday, "date")
      ? "previous"
      : "custom";

  return (
    <Wrapper>
      <Select
        options={options}
        value={picker}
        onChange={handlePickerChange}
        popupMatchSelectWidth={false}
      />
      <RangePicker
        picker="date"
        value={[startDate, endDate]}
        onChange={handleDateRangeChange}
      />
      {!isCustom && (
        <>
          <Button onClick={handleLastWeek}>&lt;</Button>
          <Button onClick={handleNextWeek}>&gt;</Button>
        </>
      )}
    </Wrapper>
  );
};

export default DateSelector;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;