import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { azureAppClientId, azureSubscriptionKey, bambooHrBaseUrl } from "api";

// Create an Axios instance
const apiClient = axios.create({
  baseURL: bambooHrBaseUrl
});

// Set up Axios interceptor to include the access token in each request
export const useApi = () => {
  const { instance, accounts } = useMsal();

  // Add a request interceptor
  apiClient.interceptors.request.use(
    async (config) => {
      const request = {
        scopes: [`${azureAppClientId}/.default`],
        account: accounts[0]
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        config.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        if (error.name === "InteractionRequiredAuthError" || error.name === "BrowserAuthError") {
          // If the token can't be acquired silently, redirect for login
          instance.acquireTokenRedirect(request);
        } else {
          console.error("Token acquisition failed:", error);
          throw error;
        }
      }

      return config;
    },
    (error) => {
      // Handle the error
      return Promise.reject(error);
    }
  );

  const get = async (url) => {
    try {
      const response = await apiClient.get(url, {
        headers: {
          "Ocp-Apim-Subscription-Key": azureSubscriptionKey,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      throw error;
    }
  };

  const post = async (url, request) => {
    try {
      const response = await apiClient.post(url, request, {
        headers: {
          "Ocp-Apim-Subscription-Key": azureSubscriptionKey,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      throw error;
    }
  };

  return { get, post };
};
