import { Button, InputNumber, Table } from "antd";
import { useTimesheetContext, useTimesheetDispatch } from "../contexts";
import styled from "styled-components";
import {
  useCommonProjects,
  useProjectAdder,
  useProjects,
  useRemoteTimesheet,
  useTimeOff,
  useTimesheetChangeTracker
} from "../hooks";
import { ProjectSelector } from ".";
import dayjs from "dayjs";
import { getFriendlyTimeString } from "../utils";
import { useAuth } from "hooks";
import { useCallback } from "react";

interface TimesheetGridProps {
  key: string;
  title: string;
  dataIndex: string;
  className?: string;
  render?: (text: string, record: any) => JSX.Element;
}

const TimesheetGrid: React.FC = () => {
  useRemoteTimesheet();
  useProjects();
  const { data: timeoffData } = useTimeOff();
  const { addDirtyEntry } = useTimesheetChangeTracker();
  const { activeTimesheet, startDate, endDate, isLoading } = useTimesheetContext();
  const { user } = useAuth();
  const { addCommonProjects, isLoading: lastWeekProjectsLoading } = useCommonProjects();

  const dispatch = useTimesheetDispatch();
  const { addProjectToTimesheet } = useProjectAdder();

  const handleHoursChange = useCallback(
    (projectId: number, date: string, remoteId: number, value: number) => {
      const updatedProjects = activeTimesheet.projects.map((project) => {
        if (project.id === projectId) {
          return {
            ...project,
            [date]: { hours: value, dirty: true, ids: remoteId ? [remoteId] : [] }
          };
        }
        return project;
      });

      dispatch({
        payload: {
          activeTimesheet: {
            ...activeTimesheet,
            projects: updatedProjects
          }
        }
      });

      addDirtyEntry({
        projectId,
        id: remoteId,
        date,
        hours: value,
        employeeId: user?.employeeId
      });
    },
    [activeTimesheet, addDirtyEntry, dispatch, user?.employeeId]
  );

  const tableColumns: TimesheetGridProps[] = [
    {
      dataIndex: "project",
      key: "project",
      title: "Projects",
      render: (text: string, record) => {
        if (record.key === "add") {
          return (
            <AddProjectsWrapper>
              <ProjectSelector addProjectToTimesheet={addProjectToTimesheet} />
              <Button onClick={addCommonProjects} loading={lastWeekProjectsLoading}>
                Add Previous Week Projects
              </Button>
            </AddProjectsWrapper>
          );
        }

        return <span>{text}</span>;
      }
    }
  ];

  let currentDate = dayjs(startDate);
  while (
    currentDate.isBefore(dayjs(endDate), "date") ||
    currentDate.isSame(dayjs(endDate), "date")
  ) {
    const key = currentDate.format("YYYY-MM-DD");
    const isToday = currentDate.isSame(dayjs(), "day");
    const isWeekend = currentDate.day() === 0 || currentDate.day() === 6;

    tableColumns.push({
      dataIndex: key,
      key: key,
      title: currentDate.format("ddd MMM D"),
      className: isToday ? "today" : isWeekend ? "weekend" : "",
      render: (text: string, record) => {
        if (record.type === "action") {
          return null;
        }
        const hours = record[key]?.hours;
        const canEdit = record[key]?.canEdit ?? true;
        const remoteId = record[key]?.remoteId;

        if (record.type === "timeoff") {
          if (!hours) {
            return null;
          }
          return <>{getFriendlyTimeString(hours)}</>;
        }

        return (
          <StyledInputNumber
            step={0.25}
            value={hours}
            disabled={!canEdit}
            onChange={(value) =>
              handleHoursChange(
                record.key,
                key,
                remoteId,
                parseFloat(value?.toString() || "0")
              )
            }
          />
        );
      }
    });
    currentDate = currentDate.add(1, "day");
  }

  // Add a column for total hours
  tableColumns.push({
    dataIndex: "total",
    key: "total",
    title: "Total Hours",
    render: (_text: string, record) => {
      if (record.type === "action") {
        return null;
      }
      const totalHours = Object.keys(record)
        .filter((key) => key !== "project" && key !== "total")
        .reduce((sum, key) => sum + (record[key]?.hours || 0), 0);
      return <span>{getFriendlyTimeString(totalHours)}</span>;
    }
  });

  const data = activeTimesheet.projects.reduce((list, item) => {
    // check if the project is already in the list
    let existingProject = list.find((project) => project.key === item.id);
    if (!existingProject) {
      existingProject = {
        key: item.id,
        project: item.name
      };
      list.push(existingProject);
    }

    Object.keys(item).forEach((key) => {
      if (key === "id" || key === "name") return;
      existingProject[key] = {
        hours: item[key].hours,
        // don't allow editing if there are multiple entries for a project on the same day
        canEdit: item[key].ids?.length <= 1,
        remoteId: item[key].ids?.length === 1 ? item[key].ids[0] : null,
        type: "project"
      };
    });
    return list;
  }, []);

  // add a row for adding a new project
  data.push({
    key: "add",
    project: "",
    type: "action"
  });

  // add rows for any time off requests
  if (timeoffData) {
    timeoffData.forEach((timeoff) => {
      const timeOffKey = `project-${timeoff.type?.name}`;
      let existingProject = data.find((project) => project.key === timeOffKey);
      if (!existingProject) {
        existingProject = {
          key: timeOffKey,
          project: timeoff.type?.name,
          type: "timeoff"
        };
      }

      Object.keys(timeoff.dates).forEach((key) => {
        existingProject[key] = {
          hours: parseFloat(timeoff.dates[key])
        };
      });

      data.push(existingProject);
    });
  }

  const summaryRow = () => {
    const totalHours = {};
    data.forEach((record) => {
      Object.keys(record).forEach((key) => {
        if (key !== "key" && key !== "project" && record[key]?.hours) {
          if (!totalHours[key]) {
            totalHours[key] = 0;
          }
          totalHours[key] += record[key].hours;
        }
      });
    });

    const totalSum = Object.values(totalHours).reduce(
      (sum, value) => (sum as number) + (value as number),
      0
    );

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
        {tableColumns.slice(1, -1).map((col, index) => (
          <Table.Summary.Cell key={col.key} index={index + 1}>
            {getFriendlyTimeString(totalHours[col.dataIndex] || 0)}
          </Table.Summary.Cell>
        ))}
        <Table.Summary.Cell index={tableColumns.length - 1}>
          <PeriodTotalWrapper>
            {getFriendlyTimeString(totalSum as number)}
          </PeriodTotalWrapper>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <Wrapper>
      <StyledTable
        loading={isLoading}
        columns={tableColumns}
        pagination={false}
        dataSource={data}
        summary={summaryRow}
      />
    </Wrapper>
  );
};

export default TimesheetGrid;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledTable = styled(Table)`
  width: 100%;
  .ant-table-row-selected > .ant-table-cell,
  .ant-table-row-selected > .ant-table-cell.ant-table-cell-row-hover {
    background-color: #fff;
  }
  .ant-table-thead > tr > th,
  .ant-table-summary > tr > td {
    background-color: #001529;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
  .today {
    background-color: rgba(243, 242, 179, 0.925) !important;
    color: #001529 !important;
  }
  .weekend {
    background-color: rgba(250, 250, 250, 0.925) !important;
    color: #001529 !important;
  }
`;

const AddProjectsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PeriodTotalWrapper = styled.div`
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
