import { useTimesheetContext, useTimesheetDispatch } from "../contexts";
import { Project } from "../models";

const useProjectAdder = () => {
  const { activeTimesheet } = useTimesheetContext();
  const dispatch = useTimesheetDispatch();
  
  const addProjectToTimesheet = (values: Project[]) => {
    if (values?.length > 0) {
      const newProjects = values.map((project) => ({
        id: project.id,
        name: project.name,
      }));
      const updatedProjects = [
        ...activeTimesheet.projects,
        ...newProjects
      ];

      dispatch({
        payload: {
          activeTimesheet: {
            ...activeTimesheet,
            projects: updatedProjects
          }
        }
      });
    }
  };

  return {
    addProjectToTimesheet
  }
};

export default useProjectAdder;