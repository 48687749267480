import styled from "styled-components";
import { useTimesheetSync } from "../hooks";
import { Button } from "antd";

const SyncStatus: React.FC = () => {
  const { pendingChanges, syncChanges } = useTimesheetSync();
  const color = pendingChanges > 0 ? "#ffc107" : "#28a745";

  const handleSave = () => {
    syncChanges();
  }

  return (
    <Wrapper>
      <SyncStatusWrapper color={color}>
        {`${pendingChanges} pending change${pendingChanges > 1 ? "s" : ""}`}
      </SyncStatusWrapper>
      {pendingChanges > 0 && <Button type="primary" onClick={handleSave}>Save</Button>}
    </Wrapper>
  );
};

export default SyncStatus;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const SyncStatusWrapper = styled.div<{ color: string }>`
  font-size: 10px;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.88);
  padding: 3px 10px;
`;
