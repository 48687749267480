import React, { ReactNode, createContext, useReducer } from "react";

import produce from "immer";

import { AuthContextState, AuthUpdates } from "./AuthContextState";

export const initialAuthContextState: AuthContextState = {
  user: null,
  isLoading: false
};
export const AuthContext = createContext(initialAuthContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const AuthDispatchContext =
  createContext<React.Dispatch<Action<AuthUpdates, "update">>>(undefined);

function AuthReducer(
  state: AuthContextState,
  action: Action<AuthUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface AuthProviderModel {
  children: ReactNode;
  state?: AuthContextState;
}

export function AuthProvider({
  children,
  state: overrideState
}: AuthProviderModel) {
  const [state, dispatch] = useReducer(
    AuthReducer,
    overrideState ?? initialAuthContextState
  );

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}
