import { useCallback } from "react";
import { TimesheetEntryHourRequest } from "../models";
import { useTimesheetContext, useTimesheetDispatch } from "../contexts";

const useTimesheetChangeTracker = () => {
  const { pendingChanges } = useTimesheetContext();
  const dispatch = useTimesheetDispatch();

  const addDirtyEntry = useCallback((entry: TimesheetEntryHourRequest) => {
    const updatedPendingChanges = pendingChanges.filter(
      (pendingEntry) =>
        pendingEntry.date !== entry.date || pendingEntry.projectId !== entry.projectId
    );

    dispatch({ payload: { pendingChanges: [...updatedPendingChanges, entry] } });
  }, [dispatch, pendingChanges]);

  return { addDirtyEntry };
};

export default useTimesheetChangeTracker;