import React from "react";
import { DateSelector, SyncStatus, TimesheetGrid } from "./components";
import styled from "styled-components";
import { Content } from "antd/es/layout/layout";
import { TimesheetProvider } from "./contexts";
import { useAuth } from "hooks";
import { Button } from "antd";
import { mdiOpenInNew, mdiTimetable } from "@mdi/js";
import Icon from "@mdi/react";

const Timesheet: React.FC = () => {
  const { user } = useAuth();

  const firstName = user?.name?.split(" ")[0];

  const handleViewOnBamboo = () => {
    if (user?.employeeId) {
      window.open(
        `https://turinganalytics.bamboohr.com/employees/timesheet/?id=${user.employeeId}`,
        "_blank"
      );
    }
  };

  const title = firstName ? `${firstName}'s Timesheet` : "Your Timesheet";

  return (
    <TimesheetProvider>
      <StyledContent>
        <Header>
          <TitleWrapper>
            <Icon path={mdiTimetable} size={1} />
            {title}
            <SyncStatus />
          </TitleWrapper>
          <ActionsWrapper>
            <Button onClick={handleViewOnBamboo} type="link">
              View on BambooHR <Icon path={mdiOpenInNew} size={0.7} />
            </Button>
            <DateSelector />
          </ActionsWrapper>
        </Header>
        <TimesheetGrid />
      </StyledContent>
    </TimesheetProvider>
  );
};

export default Timesheet;

const StyledContent = styled(Content)``;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
