import { useContext } from "react"
import { AuthDispatchContext } from "../contexts/AuthContext";

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be within an Auth Context provider");
  }

  return context;
}
