import dayjs from "dayjs";
import { useTimesheetContext } from "../contexts";
import { TimeOff } from "../models";
import { useGetTimeOffRequestsQuery } from "../queries";

const holidayDates = [
  "2024-08-05",
  "2024-09-02",
  "2024-10-14",
  "2024-12-23",
  "2024-12-24",
  "2024-12-25",
  "2024-12-26",
  "2024-12-27",
  "2025-01-01",
  "2025-02-17",
  "2025-04-18",
];

const useTimeOff = () => {
  const { startDate, endDate } = useTimesheetContext();
  const query = useGetTimeOffRequestsQuery({ startDate, endDate });

  // Add holidays to the timeoff data
  let timeOff: TimeOff[] = query?.data || []; 

  // Check if the holiday is already in the timeOff array
  const holidayExists = timeOff.some(item => item.id === "holiday");

  if (!holidayExists) {
    // filter out the holidays that are within the current timesheet period
    const holidays = holidayDates
      .map(date => dayjs(date))
      .filter(date => (date.isSame(startDate, "date") || date.isAfter(startDate, "date")) && (date.isSame(endDate, "date") || date.isBefore(endDate, "date")));

    if (holidays?.length > 0) {
      const holidayEntry = {
        name: "Holiday",
        dates: {
        },
        type: {
          id: "holiday",
          name: "Holiday"
        },
        id: "holiday",
        employeeId: "",
        start: startDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
      };

      holidays.forEach(holiday => {
        holidayEntry.dates[holiday.format("YYYY-MM-DD")] = "7.5";
      });

      timeOff.push(holidayEntry);
    }
  }

  return {
    data: timeOff,
    isLoading: query.isLoading,
  };
};

export default useTimeOff;