import { useMutation } from "react-query";

import { bambooHrBaseUrl } from "api";
import { TimesheetEntryHourRequest, TimesheetEntryHoursRequest } from "../models";
import { useApi } from "hooks";

interface IUseAddTimeEntryMutationParams {
  onSuccess?: () => void;
  onError?: (error) => void;
}

export interface IAddTimeEntryParams {
  entries: TimesheetEntryHourRequest[];
}

export const useAddTimeEntryMutation = (params: IUseAddTimeEntryMutationParams) => {
  const api = useApi();

  const { onSuccess, onError } = params;

  const addTimeEntry = async ({ entries }: IAddTimeEntryParams) => {
    const request: TimesheetEntryHoursRequest = {
      hours: entries
    };
  
    return await api.post(`${bambooHrBaseUrl}/time_tracking/hour_entries/store`, request);
  }

  return useMutation({
    mutationFn: addTimeEntry,
    onSuccess: () => {
      onSuccess && onSuccess();
    },
    onError: (error) => {
      onError && onError(error);
    }
  });
};
