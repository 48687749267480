import { useCallback } from 'react';
import { useAuthDispatch } from 'hooks';

const useLogout = () => {
  const dispatch = useAuthDispatch();

  const logout = useCallback(() => {
    dispatch({ payload: { user: null }});
  }, [dispatch]);

  return { logout };
};

export default useLogout;