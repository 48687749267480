import { useState, useRef, useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useAddTimeEntryMutation } from "../mutations/useAddTimeEntryMutation";
import { useTimesheetEntriesQueryKeys } from "../queries";
import { useTimesheetContext, useTimesheetDispatch } from "../contexts";

const autoSyncInterval = 30;

const useTimesheetSync = () => {
  const mutation = useAddTimeEntryMutation({});
  const { pendingChanges, autoSync } = useTimesheetContext();
  const queryClient = useQueryClient();
  const dispatch = useTimesheetDispatch();

  const [countdown, setCountdown] = useState(autoSyncInterval);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const syncInProgressRef = useRef(false);

  const syncChanges = useCallback(() => {
    if (pendingChanges?.length > 0) {
      syncInProgressRef.current = true;
      dispatch({ payload: { isSyncing: true } });
      mutation.mutate(
        {
          entries: pendingChanges
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(useTimesheetEntriesQueryKeys.timesheetEntries);
            dispatch({ payload: { pendingChanges: [], isSyncing: false } });
            syncInProgressRef.current = false;
          },
          onError: () => {
            syncInProgressRef.current = false;
          }
        }
      );
    }
  }, [pendingChanges, mutation, queryClient, dispatch]);

  const revertChanges = useCallback(() => {
    dispatch({ payload: { pendingChanges: [], activeTimesheet: { projects: [] } } });
    queryClient.invalidateQueries(useTimesheetEntriesQueryKeys.timesheetEntries);
  }, [queryClient, dispatch]);

  const autoSyncChanges = useCallback(() => {
    if (syncInProgressRef.current) return;

    if (autoSync) {
      syncChanges();
    }
  }, [autoSync, syncChanges]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          autoSyncChanges();
          return autoSyncInterval;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [autoSyncChanges]);

  return {
    pendingChanges: pendingChanges.length,
    countdown,
    syncChanges,
    revertChanges
  };
};

export default useTimesheetSync;
