import React from "react";
import { Button, Input } from "antd";
import { Content } from "antd/es/layout/layout";
import styled from "styled-components";
import { useLogin } from "./hooks";
import Icon from "@mdi/react";
import { mdiCheckCircle, mdiMicrosoftWindows } from "@mdi/js";
import { useAuth } from "hooks";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const { login, email, isLoading } = useLogin();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    login();
  };

  return (
    <StyledContent>
      <Wrapper>
        <h2>Turing Util Login</h2>
        <LoginWrapper>
          {email && (
            <Input type="email" placeholder="Email" value={email} disabled={true} />
          )}
          {email && (
            <ConnectionWrapper>
              <Icon path={mdiCheckCircle} size={1} />
              Connected to Microsoft
            </ConnectionWrapper>
          )}
          {user?.employeeId && (
            <ConnectionWrapper>
              <Icon path={mdiCheckCircle} size={1} />
              Connected to Bamboo
            </ConnectionWrapper>
          )}
          {!user && (
            <Button
              type="primary"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleLogin}>
              <Icon path={mdiMicrosoftWindows} size={1} />
              Sign in with Microsoft
            </Button>
          )}
          {user && (
            <Button
              type="primary"
              onClick={() => navigate("/")}>
              Begin
            </Button>
          )}
        </LoginWrapper>
      </Wrapper>
    </StyledContent>
  );
};

export default Login;

const StyledContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const Wrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  margin-top: 100px;

  h2 {
    text-align: center;
  }
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const ConnectionWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  svg {
    color: #5fb90a;
  }
`;
