import { Select } from "antd";
import { useTimesheetContext } from "../contexts";
import styled from "styled-components";
import { useState } from "react";
import { Project } from "../models";

interface ProjectSelectorProps {
  addProjectToTimesheet: (projects: Project[]) => void;
}

const ProjectSelector = ({addProjectToTimesheet} : ProjectSelectorProps) => {
  const { activeTimesheet, projects } = useTimesheetContext();

  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  const handleProjectChange = (value: string) => {
    const newProject = projects.find((project) => project.id === parseInt(value));
    if (newProject) {
      addProjectToTimesheet([newProject]);
    }
    setSelectedProject(null);
  };

  const existingProjectIds = activeTimesheet.projects.map((project) => project.id);
  const options = projects
    ?.filter((project) => !existingProjectIds.includes(project.id))
    .map((project) => ({
      key: project.id,
      label: project.name,
      value: project.id
    }));

  return (
    <Wrapper>
      <Select
        loading={projects?.length === 0}
        options={options}
        popupMatchSelectWidth={false}
        placeholder="Add Project"
        showSearch
        optionFilterProp="label"
        onChange={handleProjectChange}
        value={selectedProject}
      />
    </Wrapper>
  );
};

export default ProjectSelector;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.8);
  }
`;
