import { useMutation } from "react-query";

import { useApi } from "hooks";
import { Employee } from "../models";

export interface UseGetEmployeeDirectoryMutationParamsT {
  onError?: (error) => void;
  onSuccess?: (data: Employee) => void;
}

export interface GetEmployeeInfoParams {
  email: string;
}

export function useGetEmployeeDirectoryMutation(
  params: UseGetEmployeeDirectoryMutationParamsT
) {
  const { onError, onSuccess } = params;
  const api = useApi();

  const getEmployeeInfo = async ({ email }: GetEmployeeInfoParams) => {
    try {
      const response = await api.get(`employee?email=${email}`);
      return response.data as Employee;
    } catch (error) {
      throw new Error("Unable to fetch employee info");
    }
  };

  return useMutation({
    mutationFn: getEmployeeInfo,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    }
  });
}
