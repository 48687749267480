import { useTimesheetContext } from "../contexts";
import { useTimesheetEntriesQuery } from "../queries";

const useTimesheet = () => {
  const { startDate, endDate } = useTimesheetContext();

  const query = useTimesheetEntriesQuery({ startDate, endDate });

  return query;
};

export default useTimesheet;