import { useEffect } from "react";
import { useTimesheetDispatch } from "../contexts";
import { useProjectsQuery } from "../queries";

const useProjects = () => {
  const {data} = useProjectsQuery({});
  const dispatch = useTimesheetDispatch();

  useEffect(() => {
    if (!data) return;

    dispatch({ payload: { projects: data } });
  }, [data, dispatch]);

  return;
};

export default useProjects;