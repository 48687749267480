import { useContext } from "react";

import { TimesheetDispatchContext } from "./TimesheetContext";

export function useTimesheetDispatch() {
  const context = useContext(TimesheetDispatchContext);
  if (context === undefined) {
    throw new Error("useTimesheetDispatch must be used within a Timesheet Provider");
  }
  return context;
}