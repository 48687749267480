import { useContext } from "react"
import { AuthContext } from "../contexts/AuthContext";

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("userAuth must be within an Auth Context provider");
  }

  return context;
}
