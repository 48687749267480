import { useQuery } from "react-query";

import dayjs from "dayjs";
import { TimesheetEntryResponse } from "../models";
import { useApi, useAuth } from "hooks";

export const useTimesheetEntriesQueryKeys = {
  timesheetEntries: "get-timesheet-entries"
};

export type UseTimesheetEntriesQueryKeyT = keyof typeof useTimesheetEntriesQueryKeys;

export interface UseTimesheetEntriesQueryParamsT {
  isAutoRefetching?: boolean;
  onError?: (error) => void;
  onSuccess?: (data) => void;
  queryKey?: UseTimesheetEntriesQueryKeyT;
  select?;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

export interface UseTimesheetEntriesQueryReturnT {
  data: TimesheetEntryResponse[] | undefined;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export function useTimesheetEntriesQuery(
  params: UseTimesheetEntriesQueryParamsT
): UseTimesheetEntriesQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true, startDate, endDate } = params;
  const { user } = useAuth();
  const api = useApi();

  const fetchTimesheet = async ({ queryKey }) => {
    const [, employeeId, start, end ] = queryKey;
    try {
      const response = await api.get(
        `time_tracking/timesheet_entries?start=${start}&end=${end}&employeeIds=${employeeId}`
      );
      return response.data as TimesheetEntryResponse[];
    } catch (error) {
      throw new Error("Unable to fetch timesheet entries");
    }
  };

  const startKey = startDate.format("YYYY-MM-DD");
  const endKey = endDate.format("YYYY-MM-DD");
  const queryInfo = useQuery({
    queryKey: [useTimesheetEntriesQueryKeys.timesheetEntries, user?.employeeId, startKey, endKey],
    queryFn: fetchTimesheet,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching !== undefined ? isAutoRefetching : true
  });

  return {
    data: queryInfo.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
