import { useEffect } from "react";
import { useTimesheet } from ".";
import { useTimesheetDispatch } from "../contexts";
import { ActiveTimesheet, ActiveTimesheetProject, TimesheetEntryResponse } from "../models";

const useRemoteTimesheet = () => {
  const { data, isLoading, error } = useTimesheet();
  const dispatch = useTimesheetDispatch();

  useEffect(() => {
    if (!data) return;

    // convert the response into an active timesheet
    const projects = data.reduce((acc, curr: TimesheetEntryResponse) => {
      if (!curr.projectInfo?.project) return acc;

      if (!acc[curr.projectInfo.project.id]) {
        acc[curr.projectInfo.project.id] = {
          id: curr.projectInfo.project.id,
          name: curr.projectInfo.project.name
        };
      }

      if (!acc[curr.projectInfo.project.id][curr.date]) {
        acc[curr.projectInfo.project.id][curr.date] = {
          hours: curr.hours,
          ids: [curr.id],
          dirty: false        
        };
      } else {
        acc[curr.projectInfo.project.id][curr.date].hours += curr.hours;
        acc[curr.projectInfo.project.id][curr.date].ids.push(curr.id);
      }

      return acc;
    }, {});

    const sortedProjects: ActiveTimesheetProject[] = Object.values(projects) as ActiveTimesheetProject[];
    sortedProjects.sort((a: ActiveTimesheetProject, b: ActiveTimesheetProject) =>
      a.name.localeCompare(b.name)
    );

    const timesheet: ActiveTimesheet = {
      projects: sortedProjects
    };

    dispatch({ payload: { activeTimesheet: timesheet, pendingChanges: [] } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch({ payload: { isLoading: isLoading } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    dispatch({ payload: { error: error } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useRemoteTimesheet;
