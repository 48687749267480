import { useQuery } from "react-query";

import { Project } from "../models";
import { useApi, useAuth } from "hooks";

export const useProjectsQueryKeys = {
  getProjects: "get-projects"
};

export type UseProjectsQueryKeyT = keyof typeof useProjectsQueryKeys;

export interface UseProjectsQueryParamsT {
  isAutoRefetching?: boolean;
  onError?: (error) => void;
  onSuccess?: (data) => void;
  queryKey?: UseProjectsQueryKeyT;
  select?;
}

export interface UseProjectsQueryReturnT {
  data: Project[] | undefined;
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export function useProjectsQuery(
  params: UseProjectsQueryParamsT
): UseProjectsQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true } = params;
  const { user } = useAuth();
  const api = useApi();

  const fetchProjects = async ({ queryKey }) => {
    const [, employeeId] = queryKey;
    try {
      const response = await api.get(
        `time_tracking/employees/${employeeId}/projects`
      );
      return response.data as Project[];
    } catch (error) {
      throw new Error("Unable to fetch timesheet entries");
    }
  };

  const queryInfo = useQuery({
    queryKey: [useProjectsQueryKeys.getProjects, user?.employeeId],
    queryFn: fetchProjects,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching !== undefined ? isAutoRefetching : true
  });

  return {
    data: queryInfo.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
