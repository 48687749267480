import { useMutation } from "react-query";

import { TimesheetEntryResponse } from "../models";
import { useApi, useAuth } from "hooks";

export interface UseTimesheetEntriesQueryParamsT {
  onError?: (error) => void;
  onSuccess?: (data) => void;
}

export const useTimesheetEntriesMutation = (
  params: UseTimesheetEntriesQueryParamsT
) => {
  const { user } = useAuth();
  const api = useApi();

  const { onSuccess, onError } = params;
  
  const fetchTimesheet = async ({ startDate, endDate }) => {
    const employeeId = user?.employeeId;
    
    const start = startDate.format("YYYY-MM-DD");
    const end = endDate.format("YYYY-MM-DD");
    try {
      const response = await api.get(
        `time_tracking/timesheet_entries?start=${start}&end=${end}&employeeIds=${employeeId}`
      );
      return response.data as TimesheetEntryResponse[];
    } catch (error) {
      throw new Error("Unable to fetch timesheet entries");
    }
  };

  return useMutation({
    mutationFn: fetchTimesheet,
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    onError: (error) => {
      onError && onError(error);
    }
  });
}
