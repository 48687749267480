import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { useGetEmployeeDirectoryMutation } from "../mutations";
import { useAuthDispatch } from "hooks";
import { Employee } from "../models";

const emailMapping = (email: string) => {
  switch (email) {
    case "bsnooks@turinganalytics.net":
      return "brents@turinganalytics.net";
  }
  return email;
};

export function useLogin() {
  const dispatch = useAuthDispatch();

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");

  const handleGetEmployeeSuccess = useCallback(
    (data: Employee) => {
      if (data?.id) {
        dispatch({
          payload: {
            user: {
              email: data.email,
              employeeId: data.employeeId,
              name: name
            }
          }
        });
      }
    },
    [dispatch, name]
  );

  const { instance, accounts } = useMsal();
  const { mutate, isLoading } = useGetEmployeeDirectoryMutation({
    onSuccess: handleGetEmployeeSuccess
  });

  const handleLogin = () => {
    instance.loginRedirect();
  };

  useEffect(() => {
    const handleRedirectResponse = async () => {
      try {
        const response = await instance.handleRedirectPromise();
        if (response) {
          console.log("Login successful:", response);
          const account = response.account;
          if (account) {
            instance.setActiveAccount(account);
            const email = emailMapping(account.username);
            setEmail(email);
            mutate({ email });
          }
        } else {
          console.log("No response from redirect.");
        }
      } catch (error) {
        console.error("Redirect handling error:", error);
      }
    };

    handleRedirectResponse();
  }, [instance, mutate]);

  useEffect(() => {
    if (accounts?.length > 0 && accounts[0].username) {
      const email = emailMapping(accounts[0].username as string);
      setEmail(email);
      setName(accounts[0].name as string || "");
      mutate({ email });
    } else {
      console.log("no accounts", accounts);
    }
  }, [accounts, mutate]);

  return {
    isLoading: isLoading,
    email: email,
    login: handleLogin
  };
}
